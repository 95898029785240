exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-dobierz-lek-index-jsx": () => import("./../../../src/data/pages/dobierz-lek/index.jsx" /* webpackChunkName: "component---src-data-pages-dobierz-lek-index-jsx" */),
  "component---src-data-pages-home-index-jsx": () => import("./../../../src/data/pages/home/index.jsx" /* webpackChunkName: "component---src-data-pages-home-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-katar-i-bol-glowy-index-jsx": () => import("./../../../src/data/pages/katar-i-bol-glowy/index.jsx" /* webpackChunkName: "component---src-data-pages-katar-i-bol-glowy-index-jsx" */),
  "component---src-data-pages-katar-index-jsx": () => import("./../../../src/data/pages/katar/index.jsx" /* webpackChunkName: "component---src-data-pages-katar-index-jsx" */),
  "component---src-data-pages-katar-u-dzieci-index-jsx": () => import("./../../../src/data/pages/katar-u-dzieci/index.jsx" /* webpackChunkName: "component---src-data-pages-katar-u-dzieci-index-jsx" */),
  "component---src-data-pages-kontakt-index-jsx": () => import("./../../../src/data/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-data-pages-kontakt-index-jsx" */),
  "component---src-data-pages-mapa-strony-index-jsx": () => import("./../../../src/data/pages/mapa-strony/index.jsx" /* webpackChunkName: "component---src-data-pages-mapa-strony-index-jsx" */),
  "component---src-data-pages-nota-prawna-index-jsx": () => import("./../../../src/data/pages/nota-prawna/index.jsx" /* webpackChunkName: "component---src-data-pages-nota-prawna-index-jsx" */),
  "component---src-data-pages-polityka-cookies-aneks-index-jsx": () => import("./../../../src/data/pages/polityka-cookies-aneks/index.jsx" /* webpackChunkName: "component---src-data-pages-polityka-cookies-aneks-index-jsx" */),
  "component---src-data-pages-polityka-cookies-index-jsx": () => import("./../../../src/data/pages/polityka-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-polityka-cookies-index-jsx" */),
  "component---src-data-pages-polityka-prywatnosci-index-jsx": () => import("./../../../src/data/pages/polityka-prywatnosci/index.jsx" /* webpackChunkName: "component---src-data-pages-polityka-prywatnosci-index-jsx" */),
  "component---src-data-pages-poradnik-dzieci-odpornosc-dziecka-zadbaj-i-zwiekszaj-index-jsx": () => import("./../../../src/data/pages/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-dzieci-odpornosc-dziecka-zadbaj-i-zwiekszaj-index-jsx" */),
  "component---src-data-pages-poradnik-dzieci-walka-z-infekcjami-katar-i-kaszel-u-dziecka-index-jsx": () => import("./../../../src/data/pages/poradnik/dzieci/walka_z_infekcjami_-_katar_i_kaszel_u_dziecka_/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-dzieci-walka-z-infekcjami-katar-i-kaszel-u-dziecka-index-jsx" */),
  "component---src-data-pages-poradnik-dzieci-zatkany-nos-u-dziecka-index-jsx": () => import("./../../../src/data/pages/poradnik/dzieci/zatkany_nos_u_dziecka/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-dzieci-zatkany-nos-u-dziecka-index-jsx" */),
  "component---src-data-pages-poradnik-index-jsx": () => import("./../../../src/data/pages/poradnik/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-index-jsx" */),
  "component---src-data-pages-poradnik-katar-co-to-jest-zapalenie-zatok-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/co_to_jest_zapalenie_zatok/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-co-to-jest-zapalenie-zatok-index-jsx" */),
  "component---src-data-pages-poradnik-katar-czym-jest-katar-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/czym_jest_katar/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-czym-jest-katar-index-jsx" */),
  "component---src-data-pages-poradnik-katar-jak-prawidlowo-oczyszczac-nos-podczas-kataru-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-jak-prawidlowo-oczyszczac-nos-podczas-kataru-index-jsx" */),
  "component---src-data-pages-poradnik-katar-katar-alergiczny-poznaj-i-zapobiegaj-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-katar-alergiczny-poznaj-i-zapobiegaj-index-jsx" */),
  "component---src-data-pages-poradnik-katar-podejmij-szybka-walke-z-katarem-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/podejmij_szybka_walke_z_katarem/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-podejmij-szybka-walke-z-katarem-index-jsx" */),
  "component---src-data-pages-poradnik-katar-pokonaj-zapalenie-zatok-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/pokonaj_zapalenie_zatok/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-pokonaj-zapalenie-zatok-index-jsx" */),
  "component---src-data-pages-poradnik-katar-poznaj-rodzaje-kataru-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/poznaj_rodzaje_kataru/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-poznaj-rodzaje-kataru-index-jsx" */),
  "component---src-data-pages-poradnik-katar-skutki-niewyleczonego-kataru-index-jsx": () => import("./../../../src/data/pages/poradnik/katar/skutki_niewyleczonego_kataru/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-katar-skutki-niewyleczonego-kataru-index-jsx" */),
  "component---src-data-pages-poradnik-przeziebienie-cztery-fazy-rozwoju-przeziebienia-index-jsx": () => import("./../../../src/data/pages/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-przeziebienie-cztery-fazy-rozwoju-przeziebienia-index-jsx" */),
  "component---src-data-pages-poradnik-przeziebienie-domowe-sposoby-na-walke-z-przeziebieniem-index-jsx": () => import("./../../../src/data/pages/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-przeziebienie-domowe-sposoby-na-walke-z-przeziebieniem-index-jsx" */),
  "component---src-data-pages-poradnik-przeziebienie-jak-uchronic-sie-przed-przeziebieniem-index-jsx": () => import("./../../../src/data/pages/poradnik/przeziebienie/jak_uchronic_sie_przed_przeziebieniem/index.jsx" /* webpackChunkName: "component---src-data-pages-poradnik-przeziebienie-jak-uchronic-sie-przed-przeziebieniem-index-jsx" */),
  "component---src-data-pages-produkty-actifed-syrop-na-katar-i-kaszel-index-jsx": () => import("./../../../src/data/pages/produkty/actifed_syrop_na_katar_i_kaszel/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-actifed-syrop-na-katar-i-kaszel-index-jsx" */),
  "component---src-data-pages-produkty-index-jsx": () => import("./../../../src/data/pages/produkty/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-tabletki-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_tabletki/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-tabletki-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-xylospray-dex-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_xylospray_dex/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-xylospray-dex-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-xylospray-dex-kids-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_xylospray_dex_kids/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-xylospray-dex-kids-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-xylospray-dla-dzieci-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_xylospray_dla_dzieci/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-xylospray-dla-dzieci-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-xylospray-ha-dla-dzieci-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_xylospray_ha_dla_dzieci/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-xylospray-ha-dla-dzieci-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-xylospray-ha-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_xylospray_ha/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-xylospray-ha-index-jsx" */),
  "component---src-data-pages-produkty-sudafed-xylospray-index-jsx": () => import("./../../../src/data/pages/produkty/sudafed_xylospray/index.jsx" /* webpackChunkName: "component---src-data-pages-produkty-sudafed-xylospray-index-jsx" */),
  "component---src-data-pages-pytania-i-odpowiedzi-index-jsx": () => import("./../../../src/data/pages/pytania-i-odpowiedzi/index.jsx" /* webpackChunkName: "component---src-data-pages-pytania-i-odpowiedzi-index-jsx" */),
  "component---src-data-pages-reklama-telewizyjna-index-jsx": () => import("./../../../src/data/pages/reklama-telewizyjna/index.jsx" /* webpackChunkName: "component---src-data-pages-reklama-telewizyjna-index-jsx" */),
  "component---src-data-pages-slowniczek-index-jsx": () => import("./../../../src/data/pages/slowniczek/index.jsx" /* webpackChunkName: "component---src-data-pages-slowniczek-index-jsx" */)
}

